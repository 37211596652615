import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Container, Row, Col } from 'react-bootstrap/';

Chart.register(...registerables, annotationPlugin);

const IBABeforeAuditPateroChart = ({ score, percentiles, benchmark, setBeforePateroSrc = null }) => {

  const labels = [
    '0%-10%',
    '11%-20%',
    '21%-30%',
    '31%-40%',
    '41%-50%',
    '51%-60%',
    '61%-70%',
    '71%-80%',
    '81%-90%',
    '91%-100%',
  ];

  const getScoreColor = (score, benchmark) => {
    const diff = (benchmark - score).toFixed(2);

    if (diff <= 0.05 && diff >= -0.05) {
      return '#EBCD37';
    } else if (diff > 0.05 && diff <= 0.15) {
      return '#E6881A';
    } else if (diff > 0.15) {
      return '#CC413A';
    } else if (diff < -0.05) {
      return '#68A367'
    }
  }

  const getScoreIndex = (score) => {
    if (score >= 0 && score <= 0.10) {
      return 0;
    } else if (score >= 0.11 && score <= 0.20) {
      return 1;
    } else if (score >= 0.21 && score <= 0.30) {
      return 2;
    } else if (score >= 0.31 && score <= 0.40) {
      return 3;
    } else if (score >= 0.41 && score <= 0.50) {
      return 4;
    } else if (score >= 0.51 && score <= 0.60) {
      return 5;
    } else if (score >= 0.61 && score <= 0.70) {
      return 6;
    } else if (score >= 0.71 && score <= 0.80) {
      return 7;
    } else if (score >= 0.81 && score <= 0.90) {
      return 8;
    } else if (score >= 0.91 && score <= 1.00) {
      return 9;
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: '',
        data: percentiles,
        backgroundColor: labels.map((label, index) => index === getScoreIndex(score) ? getScoreColor(score, benchmark) : 'gray'),
        // backgroundColor: labels.map((label, index) => index === getScoreIndex(score) ? '#CC413A' : 'gray'),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return labels[ value ];
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: Math.max(...percentiles),
        ticks: {
          stepSize: 20,
        },
        grid: {
          drawTicks: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    animation: {
      onComplete: (event) => {
        if (setBeforePateroSrc) {
          setBeforePateroSrc(event.chart.toBase64Image());
        }
      },
    },
    // maintainAspectRatio: false,
  };

  return (
    <>
      <Row className="d-flex flex-row">
        <Col>
          <div>
            <Bar options={options} data={data} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IBABeforeAuditPateroChart;
