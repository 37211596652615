import React, { useRef, useState } from 'react';
import styles from './SourcingManagement.module.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { usePortal } from '../components/PortalProvider';

const SourcingManagement = () => {
    const portal = usePortal();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [subject, setSubject] = useState('');
    const [request, setRequest] = useState('');
    const [description, setDescription] = useState('');

    const fileRef = useRef();

    // sgMail.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
    // console.log(process.env.REACT_APP_SENDGRID_API_KEY);
    // console.log(process.env.REACT_APP_API_URL);

    const onFormSubmit = () => {
        // if (firstName && lastName && email && phone && company && subject && request && description) {
        //     const msg = {
        //         //to: 'spycare@spyglass.net',
        //         to: 'jared@emaildevtest.com',
        //         from: 'jared@emaildevtest.com',
        //         subject,
        //         text: description
        //     };

        //     sgMail
        //         .send(msg)
        //         .then((response) => {
        //             console.log(response[0].statusCode);
        //             console.log(response[0].headers);

        //             alert("Your request has been submitted.");
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             alert('Error occurred when sending request. Please try again');
        //         })
        // } else {
        //     alert("Please set all required fields before submitting.");
        // }
        if (firstName && lastName && email && phone && company && subject && request && description) {
            const body = {
                firstName, lastName, email, phone, company, subject, request, description
            };

            const formData = new FormData();

            for (const key in body) {
                formData.append(key, body[key]);
            }
            
            if (fileRef.current) {
                formData.append('file', fileRef.current?.files[0], fileRef.current?.files[0]?.name);
                formData.append('fileType', fileRef.current?.files[0]?.type);
            }

            fetch(`${process.env.REACT_APP_REDIRECT_URL}/sourcing-management-email.php`, {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                body: formData
            })
            .then((response) => {
                alert("Your request has been submitted.");
            })
            .catch((error) => {
                console.log(error);
                alert('Error occurred when sending request. Please try again');
            });
        }
    }

    return portal === 'SpyCare' ? (
        <>
            <Container className="mt-3">
                <Row>
                  <Col xs={12} className="d-flex justify-content-between align-content-center">
                    <h1 className="mb-0">Sourcing Management</h1>
                  </Col>
                </Row>
            </Container>
            <Container>
                <Row xs={6}>
                    <Col>
                        <Form.Group>
                            <Form.Label>First Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Last Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={6}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Email <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Phone Number <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={3}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Company <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={3}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Subject <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                type='text'
                                required
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={3}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Request <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Select 
                                required
                                value={request}
                                onChange={(e) => setRequest(e.target.value)}
                            >
                                <option value="" disabled></option>
                                <option value="Voice (POTS/Local/LD/VOIP/SIP)">Voice (POTS/Local/LD/VOIP/SIP)</option>
                                <option value="Internet">Internet</option>
                                <option value="Network (P2P/MPLS/VPN/SD-WAN)">Network (P2P/MPLS/VPN/SD-WAN)</option>
                                <option value="Mobility">Mobility</option>
                                <option value="Cloud">Cloud</option>
                                <option value="Phone System">Phone System</option>
                                <option value="UCaaS">UCaaS</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row xs={3}>
                    <Col>
                        <Form.Group>
                            <Form.Label>Description <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control 
                                as="textarea"
                                required
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Form.Label>Attachment (Optional)</Form.Label>
                    <span style={{fontSize: '11px', marginBottom: '5px'}}>Upload a bill or screenshot that you require assistance with</span>
                    <input type="file" ref={fileRef} />
                </Row>
                <Row className='mt-5'>
                    <Col>
                        <Button variant="primary" onClick={onFormSubmit}>Submit</Button>
                    </Col>
                </Row>
            </Container>
        </>
    ) : null
}

export default SourcingManagement