
import { formatAsCurrency } from '../utils';

import {
  Link, Page, Text, View, Image, Document,
  StyleSheet, usePDF, PDFViewer, PDFDownloadLink, Font
} from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
  fontWeight: 'normal'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'col',
    margin: 20,
    marginLeft: 35,
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'start',
  },
  rowSectionFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 8,
  },
  colSection: {
    flexDirection: 'column',
  },
  section: {
    marginBottom: 7,
    // padding: 10,
    flexGrow: 1
  },
  image: {
    width: 600,
    maxHeight: 500
  },
  imageModal: {
    width: 225,
    maxHeight: 200
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%'
  },
  logo: {
    width: 120,
    height: 42,
  },
  logoFooter: {
    width: 10,
    marginRight: 5,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1.4
  },
  subtitle: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1.6
  },
  subtitleCenter: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10
  },
  textHeader: {
    fontSize: 10,
    fontFamily: 'Roboto',
    marginRight: 3,
    lineHeight: 1.4
  },
  textSmall: {
    fontSize: 9,
    lineHeight: 1.4
  },
  textFooter: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    lineHeight: 1.4
  },
});

const ReportDoc = (
  headerData,
  beforeData, beforeBarSrc, beforePateroSrc,
  afterData, afterBarSrc, afterPateroSrc,
  volumeData, volumeBarSrc
) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <View>
            <Text style={styles.title}>Industry Benchmark Analysis</Text>
            <Text style={styles.subtitle}>Prepared for {headerData.clientName}</Text>
          </View>
          <Image style={styles.logo} src="\logo\SpyGlass-Logo-Transparent.png"></Image>
        </View>
        <View style={styles.rowSection}>
          <Text style={styles.textHeader}><Text style={{ fontFamily: 'Helvetica-Bold' }}>SpyGlass Clients:</Text> {headerData?.totalJobCountAll?.toLocaleString()}</Text>
          <Text style={styles.textHeader}>| <Text style={{ fontFamily: 'Helvetica-Bold' }}>Benchmark Sample:</Text> {headerData?.benchmarkSample?.toLocaleString()}</Text>
        </View>
        <View style={styles.rowSection}>
          <Text style={styles.textHeader}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Industry:</Text> {headerData.industryName}</Text>
          <Text style={styles.textHeader}>| <Text style={{ fontFamily: 'Helvetica-Bold' }}>Monthly Spend Bracket: </Text>
            {headerData.reportCardSpendBracketId === 0 ? 'All' : headerData.reportCardSpendBrackets?.find((rsb) => rsb.reportCardSpendBracketId === headerData.reportCardSpendBracketId)?.label}
          </Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.subtitleCenter}>Budget - How efficiently are dollars being spent?</Text>
        <View style={styles.rowSection}>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>Before Audit</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Annual Spend:</Text> {formatAsCurrency(beforeData.annualSpend)}</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Savings Indentified:</Text> {formatAsCurrency(beforeData.savingsIdentified)}</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>% of Spend Flagged:</Text> {(beforeData.spendFlagged * 100).toFixed(2)}%</Text>
          </View>
          <View>
            <Image style={styles.image} src={beforeBarSrc}></Image>
          </View>
        </View>
      </View>
      {/* <View style={styles.section}>
        <View style={styles.rowSection}>
          <View style={{ width: '25%' }}>
          </View>
          <View>
            <Image style={styles.imageModal} src={beforePateroSrc}></Image>
          </View>
        </View>
      </View> */}
      <View style={styles.section}>
        <View style={styles.rowSection}>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>After Audit</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Savings Implemented:</Text> {formatAsCurrency(afterData.savingsImplemented)}</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>% Implemented:</Text> {(afterData.implemented * 100).toFixed(2)}%</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Post Audit Spend:</Text> {formatAsCurrency(afterData.postAuditSpend)}</Text>
          </View>
          <View>
            <Image style={styles.image} src={afterBarSrc}></Image>
          </View>
        </View>
      </View>
      {/* <View style={styles.section}>
        <View style={styles.rowSection}>
          <View style={{ width: '25%' }}>
          </View>
          <View>
            <Image style={styles.imageModal} src={afterPateroSrc}></Image>
          </View>
        </View>
      </View> */}
      <View style={styles.section}>
        <Text style={styles.subtitleCenter}>Volume - How many recommendations were implemented?</Text>
        <View style={styles.rowSection}>
          <View style={{ width: '20%' }}>
            <Text style={styles.text}>Volume</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Total Recommendations:</Text> {volumeData.totalRecommendations}</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Implemented:</Text> {volumeData.implemented}</Text>
            <Text style={styles.textSmall}><Text style={{ fontFamily: 'Helvetica-Bold' }}>Declined:</Text> {volumeData.totalRecommendations - volumeData.implemented}</Text>
          </View>
          <View>
            <Image style={styles.image} src={volumeBarSrc}></Image>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.rowSectionFooter}>
          <View>
            <Text style={styles.textFooter}>Corporate Address</Text>
            <Text style={styles.textSmall}>25777 Detroit Road, Suite 400</Text>
            <Text style={styles.textSmall}>Westlake, OH 44145</Text>
          </View>
          <View>
            <Text style={styles.textFooter}>Contact Us</Text>
            <Text style={styles.textSmall}>Toll Free: 1.877.4.SPY.NOW (779669)</Text>
            <Text style={styles.textSmall}>Email: info@spyglass.net</Text>
          </View>
          <View>
            <Text style={styles.textFooter}>We're on Social</Text>
            <View style={styles.rowSectionFooter}>
              <Link src='https://www.linkedin.com/company/the-spyglass-group-llc-/'><Image style={styles.logoFooter} src="\social\linkedin-brands-solid.png"></Image></Link>
              <Link src='https://m.facebook.com/pages/category/Business-Consultant/The-SpyGlass-Group-LLC-572802199496951/'><Image style={styles.logoFooter} src="\social\facebook-brands-solid.png"></Image></Link>
              <Link src='https://www.instagram.com/thespyglassgroup/?hl=en'><Image style={styles.logoFooter} src="\social\instagram-brands-solid.png"></Image></Link>
              <Link src='https://www.youtube.com/channel/UCt9RACtKLmuCsMNAbk1vR0w'><Image style={styles.logoFooter} src="\social\youtube-brands-solid.png"></Image></Link>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document >
);

export default ReportDoc
